<template>
  <div class="page404">
    <span>404页面没找到</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.page404 {
    text-align: center;
    line-height: 300px;
    font-size: 100px;
    color: #ddd;
}
</style>